import { useContext, useEffect, useState } from 'react';
import SearchCriteria from './SearchCriteria';
import { useNavigate } from 'react-router';
import { SmartContext } from '../../../library/Core/SmartContext';
import useManageAlumni from '../engagement-list/useManageAlumni';
import InstituteLisingService from './InstituteLisingService';
import { getFuzzySearchCriteria } from '../../../library/Core/SmartFunctions';


const Layout = (props: any) => {
    const { state, dispatch } = useContext(SmartContext);
    const { setStateValue } = useManageAlumni();
    const [fuzzySearchText, setFuzzySearchText] = useState('');
    const [showSearchCriteria, setShowSearchCriteria] = useState(true);
    const navigate = useNavigate();
    const [isInitialLoad, setIsInitialLoad] = useState(true);

    const handleFuzzySearch = () => {
        if (!isInitialLoad) {
            const searchCriteria = {
                ...state.data.searchCriteria,
                name: fuzzySearchText
            };
            InstituteLisingService.search(searchCriteria, state.routeInfo.pageName).then((response: any) =>{
                setStateValue('alumniList', response.data);
                const fetchInstituteCount = async () => {
                    try {
                        const instituteCount = await InstituteLisingService.searchCount(getFuzzySearchCriteria(fuzzySearchText), state.routeInfo.pageName);
                                        
                        dispatch({
                            type: 'SET_INTERNAL_STATE',
                            payload: { key:"instituteCount", value:instituteCount?.data },
                        });
                    } catch (error) {
                        console.error("Error fetching alumni count:", error);
                    }
                };
            
                fetchInstituteCount();
        });
        }
    };

    const handleOnChange = (event: any) => {
        setFuzzySearchText(event.target.value);
    };

    useEffect(() => {
        setIsInitialLoad(false); 
        handleFuzzySearch();
    }, []);

    // useEffect(() => {
    //     handleFuzzySearch(); 
    // }, [fuzzySearchText]);

    
    useEffect(() => {
        setFuzzySearchText('');
    }, [state.data.searchCriteria]);


    const handleToggleSearchCriteria = () => {
        setShowSearchCriteria(!showSearchCriteria);
    };

    return (
        <div className="main flex-1">
            <section className="updateadditionalinformation">
                <div className="container max-1140 px-lg-0 overflow-hidden">
                    <div className="row">
                        <div className="col-md-12 mb-4">
                            <div className="white-block white-block-notopborderradius p-0 h-100">
                                <div className="p-4 p-md-5">
                                    <div className="alumni_statistics">
                              
                                     <div className="d-flex justify-content-between align-items-center mb-3 pb-2">
                                        <h3 className="font-35 font-600 mb-0">{state.formConfig?.title}</h3>
                                        <div className="dashboard innerpage smart-scroll scrolled-down">
                                            <button className='btn btn-green' onClick={() => {navigate('/institute/new/basic-info')}}><span>Add Institute</span></button>                            
                                        </div>
                                    </div>
                                        <div className="d-flex flex-wrap justify-content-between align-items-start align-items-md-center mb-2 filterSearch">
                                            <div className="d-flex flex-wrap flex-1 mb-0 justify-content-start">
                                                <button className="btn-grpfiter me-3" type="button" onClick={handleToggleSearchCriteria}>
                                                    <i className="icon-filter font-13 me-2"></i>
                                                    Filters
                                                </button>
                                                <div className="search alumniSearch m-0 open position-static flex-1">
                                                    <div className="form-inline d-flex flex-wrap justify-content-between d-block">
                                                        <input
                                                            value={fuzzySearchText}
                                                            placeholder="Search by Institute name"
                                                            aria-label="Search"
                                                            className="form-control flex-1 font-13 font-500 text-truncate"
                                                            onChange={handleOnChange}
                                                        />
                                                        <button
                                                            className="btn btn-search icon icon-search"
                                                            onClick={handleFuzzySearch}></button>
                                                    </div>
                                                    {/* <a href="" className="btn-search-toggle icon-search"></a> */}
                                                </div>
                                            </div>
                                        </div>
                                        {showSearchCriteria && <SearchCriteria />}
                                        {props.children}
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
        </div>
    );
};

export default Layout;
