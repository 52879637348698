import { useContext, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { SmartContext } from '../../../../library/Core/SmartContext';
import WorkSimilarAlumni from '../controls/WorkSimilarAlumni';
import SessionContext from '../../../../library/Core/SessionContext';
import { getCompanyImage } from '../../../../common/styles/Images';
import { getFlexColDomainValueForCode, isEmpty } from '../../../../library/Core/SmartFunctions';

const ProfileWorkExperience = () => {
    const { state, dispatch } = useContext(SmartContext);
    const { sessionState } = useContext(SessionContext);
    const model = state.internal.model;
    const navigate = useNavigate();

    const [isActive, setIsActive] = useState(false);
    const [isHidden, setIsHidden] = useState(false);

    const [actionBtnStatus, setActionBtnStatus]: any = useState({});

    const handleClick = (id: any) => {
      
        setIsActive(!isActive);
        setIsHidden(!isHidden);

        setActionBtnStatus({
            ...actionBtnStatus,
            [id]: ! actionBtnStatus[id]
        });
    };

    const isPeerReviewed = sessionState?.isPeerReviewed ?? false;
    const userType = sessionStorage.getItem('user-type-code');
    const isPersonate = sessionStorage.getItem('is-impersonated-session') == 'YES' ? true : false;
    if (isPersonate != true && userType == 'ALUMNI' && isPeerReviewed != true) {
        window.location.href = `/alumni/welcome`;
        return;
    }

    function getImage(workId: any, jobCategory: any) {
        const flexCol = getFlexColDomainValueForCode(workId, 
            jobCategory === 'TEACHING_AND_RESEARCH' ? 'INSTITUTE_DOMAIN' : 'COMPANY_CODE', state);
        const company_logo = isEmpty(flexCol) ? '' : JSON.parse(flexCol).logo;
        const logo = getCompanyImage(company_logo);
        return logo;
    }
    
    return (
        <div>
            <div className="workExperience-detail">
                <div className="blockHeader">
                    <div className="row g-2  align-items-center">
                        <div className="col">
                            {!model.isEditable && (
                                <div className="title">
                                    Companies{' '}
                                    <span>
                                        {`${model.firstName}`} {model.lastName ? `${model.lastName}` : ''}
                                    </span>{' '}
                                    has worked for
                                </div>
                            )}
                            {model.isEditable && <div className="title">My Work Experience</div>}
                        </div>
                        {model.isEditable && (
                            <div className="col-auto">
                                <a role="button" onClick={() => navigate(`/alumni/${model.id}/work`)} className="btn-add-section">
                                    <i></i> <span>ADD</span>
                                </a>
                                 {/* <a
                                    role="button"
                                    onClick={() => 
                                        navigate(
                                        state?.data?.profile?.workingStatusCode === 'TEACHING_AND_RESEARCH' 
                                            ? `/alumni/${model.id}/teaching-experience` 
                                            : `/alumni/${model.id}/work`
                                        )
                                    } className="btn-add-section"><i></i> <span>ADD</span></a> */}
                            </div>
                        )}
                    </div>
                </div>
                <div className="workExperienceRow">
                    <div className="row g-4 g-md-5">
                        {model.workExperience.map((work: any) => (
                            <div key={`${work.id}-${work.companyName}`} className="col-md-6">
                                <div className="work-block">
                                    {/* added by Vp #1201 @18-11-24 */}
                                    <div className="icon workex-logo">
                                     <img className="img-fluid" 
                                     src={getImage(work.companyId,work.jobCategory)} 
                                     alt="" />
                                    </div>
                                    {/* end */}

                                    <div className="work-detail flex-row align-content-between">
                                        <div className="d-flex flex-column">
                                            <p className="work-designation">{work.designation}</p>
                                            <span>
                                                {/* {work.companyName}, {work.startYear} - {work.endYear} */}
                                                {work.companyName}{work.startYear && (`, ${work.startYear}`)} {work.endYear && (` - ${work.endYear}`)}
                                            </span>
                                        </div>
                                        {model.isEditable && (
                                            <div className={`edit_section ${actionBtnStatus[work.id] ? 'open' : ''} ${isHidden ? 'hidden' : ''}`}>
                                                <button className="edit_section_toggle" onClick={() => {handleClick(work.id)}}></button>
                                                <div className="edit_section_inner">
                                                    {/* <a
                                                        role="button"
                                                        onClick={() => navigate(`/alumni/${model.id}/work`)}
                                                        className="edit_link">
                                                        Edit
                                                    </a> */}
                                                     <a
                                                        role="button"
                                                        onClick={() => {
                                                            const path = work.jobCategory === 'TEACHING_AND_RESEARCH' 
                                                                ? `/alumni/${model.id}/teaching-experience`
                                                                : work.jobCategory === 'PROFESSIONAL_PRACTICE' 
                                                                ? `/alumni/${model.id}/professional-practice-experience`
                                                                : `/alumni/${model.id}/work`;

                                                            navigate(path);
                                                        }}
                                                        className="edit_link"
                                                    >
                                                        Edit
                                                 </a>
                                                </div>
                                            </div>
                                        )}


                                     
                                    </div>
                                </div>
                            </div>
                        ))}
                    </div>
                </div>
            </div>
            <WorkSimilarAlumni />
        </div>
    );
};

export default ProfileWorkExperience;
