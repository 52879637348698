import { getCompanyImage, getUniversityImage } from '../../common/styles/Images';
import { getDomainValueForCode, getFlexColDomainValueForCode, isEmpty, recentPassoutYear } from '../Core/SmartFunctions';

const universityProgramLevel = ['UG', 'PG', 'Doctoral'];

const FULL_TIME_JOB_CODE = 'FULL_TIME';
const STATUS_WORKING = 'WORKING';
const STATUS_POST_GRADUATION = 'PG';
const STATUS_UNDER_GRADUATION = 'UG';
const STATUS_SCHOOL_STUDY = 'K12';
const STATUS_NOT_WORKING = 'TOOK_BREAK';
const STATUS_ENTRANCE_EXAM = 'ENTRANCE_EXAM';
const STATUS_DOCTORAL = 'Doctoral';
const STATUS_WORKING_HOMEMAKER = 'HOME_MAKER';
const STATUS_WORKING_TEACHING_AND_RESEARCH = 'TEACHING_AND_RESEARCH';
const STATUS_WORKING_PROFRESSIONAL_PRAC = 'PROFESSIONAL_PRACTICE';
const STATUS_WORKING_TEACHING = 'TEACHING_AND_RESEARCH';
const STATUS_PREPARED_EXAM = 'PREPARED_EXAM';
const STATUS_NO_PREPARED_EXAM = 'NO_PREPARED_EXAM';
const STATUS_WORKING_OUTCOME = 'WORKING_OUTCOME';

let current_logo = '';
let current_name = '';
let current_field = '';
let current_type_text = '';
let current_status = '';
let showCurretnStatusDiv = true;
const workingStatus = ['WORKING_FOR_A_COMPANY', 'ENTREPRENEURSHIP', 'PROFESSIONAL_PRACTICE', 'IN_DEFENCE', 'TEACHING_AND_RESEARCH'];

export const getCardType = (alumni: any, cardViewMode: string) => {
    // console.log('alumni', alumni);
    // console.log(cardViewMode, ' cardViewMode 17');
    // if (cardViewMode === 'outcomeView') {
    if (cardViewMode === 'OUTLOOK') {
        // if (alumni.postProgramCompletionStatusCode === STATUS_UNDER_GRADUATION) {
        if (alumni.postProgramCompletionStatusCode === 'JOINED_UG') {
            return 'JOINED_UG';
            // } else if (alumni.postProgramCompletionStatusCode === STATUS_POST_GRADUATION) {
        } else if (alumni.postProgramCompletionStatusCode === 'JOINED_PG') {
            return 'JOINED_PG';
        } else if (alumni.postProgramCompletionStatusCode === 'JOINED_SCHOOL') {
            return 'JOINED_SCHOOL';
            // } else if (alumni.postProgramCompletionStatusCode === STATUS_WORKING) {
        } else if (
            alumni.postProgramCompletionStatusCode === 'ENTREPRENEURSHIP' ||
            alumni.postProgramCompletionStatusCode === 'TEACHING_AND_RESEARCH' || 
            alumni.postProgramCompletionStatusCode === 'PROFESSIONAL_PRACTICE' ||
            alumni.postProgramCompletionStatusCode === 'JOINED_COMPANY'
        ) {
            return STATUS_WORKING_OUTCOME;
        } else if (alumni.postProgramCompletionStatusCode === 'APPEARED_FOR_COMPETITIVE_EXAM') {
            return STATUS_ENTRANCE_EXAM;
        } else if (alumni.postProgramCompletionStatusCode === 'TOOK_BREAK') {
            return STATUS_NOT_WORKING;
        } else if (alumni.postProgramCompletionStatusCode === 'Doctoral' || alumni.postProgramCompletionStatusCode === 'JOINED_DOCTORAL') {
            return STATUS_DOCTORAL;
        }
    }
    // TODO: Card Type determination logic need to be implemented here
    // if (!isEmpty(alumni.workingStatusCode) && alumni.jobRoleCode === FULL_TIME_JOB_CODE) {
    //     return STATUS_WORKING;
    // }

    // check if status is persuing study and what is the last saved persuing levele UG/PG or School, if not found return took a break
    if (!isEmpty(alumni?.presentEducationStatusCode)) {
        if (alumni?.presentEducationStatusCode == 'COMPLETED_STUDIES') {
            return getCardStatusTitleForCompletedStudies(alumni);
        } else {
            return getCardStatusTitleForPursuing(alumni.pursuingEducationLevelCode, alumni?.jobRoleCode);
        }
    } else {
        if (!isEmpty(alumni?.pgUniversityId)) {
            return STATUS_POST_GRADUATION;
        } else if (isEmpty(alumni?.universityId) || !isEmpty(alumni.universityName)) {
            return STATUS_UNDER_GRADUATION;
        } else if (!isEmpty(alumni?.studentOutcomesSchoolId) || isEmpty(alumni?.studentOutcomesSchoolName)) {
            return STATUS_SCHOOL_STUDY;
        }
    }

    console.log(alumni);
    return STATUS_WORKING;
};

const getCardStatusTitleForCompletedStudies = (alumni: any) => {
    if (!isEmpty(alumni?.isWorking) && alumni?.isWorking && alumni?.workingStatusCode !== STATUS_WORKING_PROFRESSIONAL_PRAC) {
        return STATUS_WORKING;
    } else if (
        !isEmpty(alumni?.workingStatusCode) &&
        (alumni?.workingStatusCode === STATUS_WORKING_HOMEMAKER || alumni?.workingStatusCode === STATUS_WORKING_PROFRESSIONAL_PRAC)
    ) {
        return STATUS_WORKING_HOMEMAKER;
    } else if (!isEmpty(alumni?.workingStatusCode) && alumni?.workingStatusCode === 'NOT_WORKING') {
        if (alumni?.preparingForExam === 1) {
            return STATUS_PREPARED_EXAM;
        } else if (!isEmpty(alumni?.pgUniversityId) || !isEmpty(alumni?.pgUniversityName)) {
            //this handle for migrated data
            return STATUS_POST_GRADUATION;
        } else if (!isEmpty(alumni?.universityId) || !isEmpty(alumni?.universityName)) {
            //this handle for migrated data
            return STATUS_UNDER_GRADUATION;
        } else {
            return STATUS_NO_PREPARED_EXAM; //take a break card
        }
    } else if (!isEmpty(alumni?.pgUniversityId) || !isEmpty(alumni?.pgUniversityName)) {
        //this handle for migrated data
        return STATUS_POST_GRADUATION;
    } else if (!isEmpty(alumni?.universityId) || !isEmpty(alumni?.universityName)) {
        //this handle for migrated data
        return STATUS_UNDER_GRADUATION;
    } else {
        return STATUS_NO_PREPARED_EXAM;
    }
};

const getCardStatusTitleForPursuing = (EducationLevelCode: any, jobRoleCode: any) => {
    console.log('EducationLevelCode', EducationLevelCode);
    if (!isEmpty(EducationLevelCode) && EducationLevelCode === STATUS_POST_GRADUATION) {
        return STATUS_POST_GRADUATION;
    } else if (!isEmpty(EducationLevelCode) && EducationLevelCode === STATUS_UNDER_GRADUATION) {
        return STATUS_UNDER_GRADUATION;
    } else if (!isEmpty(EducationLevelCode) && !universityProgramLevel.includes(EducationLevelCode)) {
        return STATUS_SCHOOL_STUDY;
    } else if (!isEmpty(EducationLevelCode) && EducationLevelCode === STATUS_DOCTORAL) {
        return STATUS_DOCTORAL;
    } else {
        return STATUS_NOT_WORKING;
    }
};

export const getProfileHeadeData = (
    state: any,
    model: any
): {
    current_logo: string;
    current_name: string;
    current_field: string;
    current_type_text: string;
    current_status: string;
    showCurretnStatusDiv: boolean;
    interestedInUpSkilling: boolean;
    interestedInHigherEducation: boolean;
} => {
    let workDetailsProcessed = false;
    const isWorking = workingStatus.includes(state?.data?.profile?.workingStatusCode);
    const interestedInUpSkilling = state?.data?.skillInfo?.interestedInUpskilling ?? false;
    const interestedInHigherEducation = state?.data?.educationPreferenceInfo?.doYouRequireApplicationSupportForPgAdmission ?? false;

    let job = state?.data?.jobsList?.filter((job: any) => job?.isCurrent === true);
    job = job.length === 1 ? job : state?.data?.jobsList;

    // if (!isEmpty(state?.data?.profile?.workingStatusCode) && job[0]?.jobRoleCode === FULL_TIME_JOB_CODE) {
    //     getWorkDetails(state, model);
    //     workDetailsProcessed = true;
    // }

    if (!workDetailsProcessed) {
        console.log(state?.data?.profile?.presentEducationStatusCode);
        if (!isEmpty(state?.data?.profile?.presentEducationStatusCode)) {
            if (state?.data?.profile?.presentEducationStatusCode == 'COMPLETED_STUDIES') {
                //here add condition for completed
                if (isWorking && state?.data?.profile?.workingStatusCode !== STATUS_WORKING_PROFRESSIONAL_PRAC) {
                    getWorkDetails(state, model);
                } else if (
                    !isEmpty(state?.data?.profile?.workingStatusCode) &&
                    (state?.data?.profile?.workingStatusCode === STATUS_WORKING_HOMEMAKER ||
                        state?.data?.profile?.workingStatusCode === STATUS_WORKING_PROFRESSIONAL_PRAC)
                ) {
                    if (state?.data?.profile?.workingStatusCode === STATUS_WORKING_HOMEMAKER) {
                        current_logo = 'https://ags-images-bucket.s3.ap-south-1.amazonaws.com/images/home-icon-directory.svg';
                        current_name = 'Home Maker';
                        if (state?.data?.profile?.homeMakerStartYear) {
                            current_field = `Since ${state?.data?.profile?.homeMakerStartYear}`;
                        } else {
                            current_field = ''; // Or any other default value you want to set
                        }
                        current_type_text = 'Working As';
                        current_status = 'Home Maker';
                    } else {
                        let flexCol: any = getFlexColDomainValueForCode(job[0]?.companyId, 'COMPANY_CODE', state);
                        current_logo =
                            state?.data?.profile?.workingStatusCode !== 'HOME_MAKER'
                                ? getCompanyImage(state?.data?.profile?.jobCompanyImage)
                                : 'https://ags-images-bucket.s3.ap-south-1.amazonaws.com/images/home-icon-directory.svg';
                        current_name =
                            job[0]?.companyId === null
                                ? job[0]?.otherCompany
                                : getDomainValueForCode(job[0]?.companyId, 'COMPANY_CODE', state);
                        current_field = job[0]?.designation?.replace('_', ' ');
                        current_type_text = '';
                        // current_status = state?.data?.profile?.workingStatusCode === 'HOME_MAKER' ? 'Homemaker' : 'Professional Practice';
                        current_status =
                            state?.data?.profile?.workingStatusCode === 'HOME_MAKER'
                                ? 'Homemaker'
                                : state?.data?.profile?.workingStatusCode === 'ENTREPRENEURSHIP'
                                ? 'Entrepreneurship'
                                : 'Professional Practice';
                    }
                } else if (state?.data?.profile?.workingStatusCode === 'NOT_WORKING') {
                    if (state?.data?.profile?.preparingForExam) {
                        const entranceTests = state?.data?.entranceTests;
                        // Filter the entrance tests with recordTypeCode 'APPLIED' and sort by createdAt in descending order
                        const appliedEntranceTests = entranceTests
                            .filter((test: any) => test.recordTypeCode === 'APPLIED')
                            .sort((a: any, b: any) => a.id - b.id); // Sorting by test id
                        if (appliedEntranceTests.length > 0) {
                            const latestEntranceTest = appliedEntranceTests[0];
                            const preparationStatusCode = latestEntranceTest?.preparationStatusCode ?? '';
                            const status = getDomainValueForCode(preparationStatusCode, 'ENTRANCE_EXAM_PREPARATION_STATUS', state);

                            const domainValue = getDomainValueForCode(latestEntranceTest?.entranceTestCode, 'ENTRANCE_TEST_CODE', state);
                            current_field = `${domainValue}, ${status}`;

                            current_logo = 'https://www.univariety.xyz/prototype/alumni-university/images/icon-note.svg';
                            current_name = 'Preparing for Exam';
                            current_type_text = '';
                            current_status = 'Preparing for Exam';
                        }
                    } else if (!isEmpty(model?.postGradColleges[0])) {
                        // this case handle for old migrated data
                        const pg = model?.postGradColleges[0];
                        current_logo = getUniversityImage(pg?.logoFileName);
                        current_name = pg?.universityName;
                        //current_field = pg?.fieldOfStudy;
                        current_field = getDomainValueForCode(pg?.fieldOfStudy, 'FIELD_OF_STUDY_TYPE_CODE_ALUMNI', state);
                        current_type_text = 'JOINED COLLEGE (PG)';
                        current_status = 'Completed PG';
                    } else if (!isEmpty(model?.joinedCollege)) {
                        // this case handle for old migrated data
                        current_logo = getUniversityImage(model?.joinedCollege?.logoFileName);
                        current_name = model?.joinedCollege?.universityName;
                        //current_field = model?.joinedCollege?.fieldOfStudy;
                        current_field = getDomainValueForCode(model?.joinedCollege?.fieldOfStudy, 'FIELD_OF_STUDY_TYPE_CODE_ALUMNI', state);
                        current_type_text = 'JOINED COLLEGE (UG)';
                        current_status = 'Completed UG';
                    } else {
                        getTookABreakDetails(state, model);
                    }
                } else {
                    getTookABreakDetails(state, model);
                }
                //end
            } else {
                if (
                    !isEmpty(state?.data?.profile?.pursuingEducationLevelCode) &&
                    state?.data?.profile?.pursuingEducationLevelCode === STATUS_POST_GRADUATION
                ) {
                    const pg = model?.postGradColleges[0];
                    current_logo = getUniversityImage(pg?.logoFileName);
                    current_name = pg?.universityName;
                    // current_field = pg?.fieldOfStudy;
                    current_field = getDomainValueForCode(pg?.fieldOfStudy, 'FIELD_OF_STUDY_TYPE_CODE_ALUMNI', state);
                    current_type_text = 'JOINED COLLEGE (PG)';
                    current_status = 'Pursuing PG';
                } else if (
                    !isEmpty(state?.data?.profile?.pursuingEducationLevelCode) &&
                    state?.data?.profile?.pursuingEducationLevelCode === STATUS_UNDER_GRADUATION
                ) {
                    current_logo = getUniversityImage(model?.joinedCollege?.logoFileName);
                    current_name = model?.joinedCollege?.universityName;
                    //current_field = model?.joinedCollege?.fieldOfStudy;
                    current_field = getDomainValueForCode(model?.joinedCollege?.fieldOfStudy, 'FIELD_OF_STUDY_TYPE_CODE_ALUMNI', state);
                    current_type_text = 'JOINED COLLEGE (UG)';
                    current_status = 'Pursuing UG';
                } else if (
                    !isEmpty(state?.data?.profile?.pursuingEducationLevelCode) &&
                    !universityProgramLevel.includes(state?.data?.profile?.pursuingEducationLevelCode)
                ) {
                    getSchoolDetails(state, model);
                } else if (
                    !isEmpty(state?.data?.profile?.pursuingEducationLevelCode) &&
                    state?.data?.profile?.pursuingEducationLevelCode === STATUS_DOCTORAL
                ) {
                    if (model?.postGradColleges && model.postGradColleges.length > 0) {
                        const pg = model?.postGradColleges[0];
                        current_logo = getUniversityImage(pg?.logoFileName);
                        current_name = pg?.universityName;
                        // current_field = pg?.fieldOfStudy;
                        current_field = getDomainValueForCode(pg?.fieldOfStudy, 'FIELD_OF_STUDY_TYPE_CODE_ALUMNI', state);
                    } else {
                        current_logo = getUniversityImage(model?.joinedCollege?.logoFileName);
                        current_name = model?.joinedCollege?.universityName;
                        // current_field = model?.joinedCollege?.fieldOfStudy;
                        current_field = getDomainValueForCode(model?.joinedCollege?.fieldOfStudy, 'FIELD_OF_STUDY_TYPE_CODE_ALUMNI', state);
                    }
                    current_type_text = 'JOINED COLLEGE (Doctoral)';
                    current_status = 'Pursuing Doctoral';
                } else {
                    getTookABreakDetails(state, model);
                }
            }
        } else {
            if (!isEmpty(state?.data?.profile?.pgUniversityId)) {
                current_logo = getUniversityImage(model?.joinedCollege?.logoFileName);
                current_name = model?.joinedCollege?.universityName;
                // current_field = model?.joinedCollege?.fieldOfStudy;
                current_field = getDomainValueForCode(model?.joinedCollege?.fieldOfStudy, 'FIELD_OF_STUDY_TYPE_CODE_ALUMNI', state);
                current_type_text = 'JOINED COLLEGE (UG)';
                current_status = 'Pursuing UG';
            } else if (isEmpty(state?.data?.profile?.universityId) || !isEmpty(state?.data?.profile.universityName)) {
                current_logo = getUniversityImage(model?.joinedCollege?.logoFileName);
                current_name = model?.joinedCollege?.universityName;
                //current_field = model?.joinedCollege?.fieldOfStudy;
                current_field = getDomainValueForCode(model?.joinedCollege?.fieldOfStudy, 'FIELD_OF_STUDY_TYPE_CODE_ALUMNI', state);
                current_type_text = 'JOINED COLLEGE (UG)';
                current_status = 'Pursuing UG';
            } else if (
                !isEmpty(state?.data?.profile?.studentOutcomesSchoolId) ||
                isEmpty(state?.data?.profile?.studentOutcomesSchoolName)
            ) {
                getSchoolDetails(state, model);
            }
        }
    }
    return {
        current_logo,
        current_name,
        current_field,
        current_type_text,
        current_status,
        showCurretnStatusDiv,
        interestedInUpSkilling,
        interestedInHigherEducation,
    };
};

const getWorkDetails = (state: any, model: any) => {
    console.log('state?.data?.profile?.workingStatusCode', state?.data?.profile?.workingStatusCode);
    let job = state?.data?.jobsList?.filter((job: any) => job?.isCurrent === true);
    job = job.length === 1 ? job : state?.data?.jobsList;
    console.log("job",job)
    console.log('Job Category' + job.jobCategory);
    console.log(job);
    // if (job[0].jobCategory === 'TEACHING_AND_RESEARCH') {
    if (state?.data?.profile?.workingStatusCode === 'TEACHING_AND_RESEARCH') {
        console.log('teaching and research' + job[0]?.instituteId);
        let flexCol: any = getFlexColDomainValueForCode(job[0]?.instituteId, 'INSTITUTE_DOMAIN', state);
        current_logo = getCompanyImage(isEmpty(flexCol) ? '' : JSON.parse(flexCol).logo);
        current_name =
            job[0]?.instituteId === null ? job[0]?.otherInstitute : getDomainValueForCode(job[0]?.instituteId, 'INSTITUTE_DOMAIN', state);
        current_field = job[0]?.designation?.replace('_', ' ');
    } else {
        let flexCol: any = getFlexColDomainValueForCode(job[0]?.companyId, 'COMPANY_CODE', state);
        current_logo = getCompanyImage(isEmpty(flexCol) ? '' : JSON.parse(flexCol).logo);
        current_name = job[0]?.companyId === null ? job[0]?.otherCompany : getDomainValueForCode(job[0]?.companyId, 'COMPANY_CODE', state);
        current_field = job[0]?.designation?.replace('_', ' ');
    }

    current_type_text = 'CURRENT COMPANY';
    // current_status = 'Working';
    current_status =
        state?.data?.profile?.workingStatusCode === 'ENTREPRENEURSHIP'
            ? 'Entrepreneurship'
            : state?.data?.profile?.workingStatusCode === 'TEACHING_AND_RESEARCH'
            ? 'Teaching and Research'
            : 'Working';
};

const getTookABreakDetails = (state: any, model: any) => {
    const passoutYear = recentPassoutYear(state?.data);
    current_logo = 'https://www.univariety.xyz/prototype/alumni-university/images/tea-cup.svg';
    current_name = 'Taking a break';
    current_field = `Since ${passoutYear}`;
    current_type_text = 'Break';
    current_status = 'Taking a break';
};

const getSchoolDetails = (state: any, model: any) => {
    const schoolId = state?.data?.schoolOutComesDetail?.instituteId;
    const flexCol = getFlexColDomainValueForCode(schoolId, 'INSTITUTE_DOMAIN', state);
    const logo = isEmpty(flexCol) ? '' : JSON.parse(flexCol).logo;
    current_logo = getUniversityImage(logo);
    current_name = schoolId ? getDomainValueForCode(schoolId, 'INSTITUTE_DOMAIN', state) : state?.data?.schoolOutComesDetail?.otherSchool;
    current_field = '';
    current_name = current_name ?? state?.data?.userInstituteName; //userInstituteName refer here instead of schoolName management login not fetch this
    current_type_text = 'JOINED SCHOOL';
    current_status = 'Pursuing School';
};
