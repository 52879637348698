import React, { useContext, useState, useEffect, useRef } from 'react';
import { SimpleFormControlArguments, State } from '../../../library/Core/SmartTypes';
import ReactQuill from 'react-quill';
import 'react-quill/dist/quill.snow.css'; // Import the styles
import DatePicker, { setDefaultLocale } from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';
import axios from 'axios';

const TemplatePreview = (args: SimpleFormControlArguments) => {
    let sesinstituteId = 0;
    const userDataString = sessionStorage.getItem('user');
    if (userDataString) {
        const userData = JSON.parse(userDataString);
        sesinstituteId = userData.primaryInstitute;
    }
    const schoolId = sesinstituteId;
    const urlParams = new URLSearchParams(window.location.search);
    const templateId = urlParams.get('template_id');
    const [subject, setSubject] = useState<string>('');
    const [message, setMessage] = useState<string>('');
    const [sender, setSender] = useState<string>('');
    const [audience, SetAudience] = useState<string>('');
    const [previewBody, SetPreviewBody] = useState<string>('');
    const [attached_file_path, setAttachedFilePath] = useState<string>('');
    const [numberOfStudents, setNumberOfStudents] = useState<number>(0);
    const [receiver_type, setReceiverType] = useState<number>(0);
    const [schoolImage, setSchoolImage] = useState<string>('');
    const [startDate, setStartDate] = useState<Date | null>(new Date());

    const handleDateChange = (date: Date | null): void => {
        setStartDate(date);
    };
 //alert(numberOfStudents);
    const temp_id = urlParams.get('temp_id');

    useEffect(() => {
        const fetchDataAndSendNow = async () => {
            await fetchData();
            const selectedStudentsString = sessionStorage.getItem('student_data_for_email');
            if (selectedStudentsString) {
                const selectedStudents: string[] = JSON.parse(selectedStudentsString);
                const parsedStudents: string[] = selectedStudents.map((uuid: string) => uuid);
                const numberOfStudents = parsedStudents.length;
                setNumberOfStudents(numberOfStudents);

                try {
                    const response = await axios.post(`${process.env.REACT_APP_BROADCAST_SERVER}/email/update-count`, {
                        audiencecount: numberOfStudents,
                        templateid: templateId,
                    });
                } catch (error) {
                    console.error('Error sending email:', error);
                }
            }
        };
        fetchDataAndSendNow();
    }, []);

    useEffect(() => {
        fetchData();
        fetchTemplate();
    }, []);

    const fetchData = async () => {
        try {
            const response = await axios.post(`${process.env.REACT_APP_BROADCAST_SERVER}/email/get-template`, {
                id: templateId,
            });

            const messageFromAPI = response.data['message'];
            const subjectFromAPI = response.data['subject'];
            const senderFromAPI = response.data['sender'];
            const audienceFromAPI = response.data['audience_count'];
            const attachedFilePathFromAPI = response.data['attached_file_path'];
            const receiver_type = response.data['receiver_type'];
            const schoolImage = response.data['university_logo'];

            const updatedMessage = messageFromAPI
                .replace(
                    /style="background-color: rgb\(92, 177, 72\); color: rgb\(255, 255, 255\);"/g,
                    'style="background-color: #5cb148; border: 0px solid transparent; border-radius: 25px; color: #ffffff; display: inline-block; font-family: Arial, Helvetica, sans-serif; font-size: 14px; font-weight: 400; mso-border-alt: none; padding: 8px 40px; text-align: center; text-decoration: none; width: auto; word-break: keep-all; line-height: 28px; letter-spacing: normal;"'
                )
                .replace(
                    /style="color: rgb\(255, 255, 255\); background-color: rgb\(92, 177, 72\);/g,
                    'style="background-color: #5cb148; border: 0px solid transparent; border-radius: 25px; color: #ffffff; display: inline-block; font-family: Arial, Helvetica, sans-serif; font-size: 14px; font-weight: 400; mso-border-alt: none; padding: 8px 40px; text-align: center; text-decoration: none; width: auto; word-break: keep-all; line-height: 28px; letter-spacing: normal;"'
                )
                .replace(
                    /<ul><li>/g,
                    '<ul style="background-image: url(https://d15k2d11r6t6rl.cloudfront.net/public/users/Integrators/f7683d20-2948-460f-8f91-a13fe519b593/MA9APFJAMODXI6YT3IKC239C/curve.png); font-family: Arial, Helvetica, sans-serif; margin: 0; padding: 30px; background-color: #0b3454; border-radius: 5px; color: #fff; background-position: right -1px bottom -1px; background-repeat: no-repeat; line-height: 24px; font-size: 16px; list-style: none; text-align: left;"><li>'
                );

            setMessage(updatedMessage);
            setSubject(subjectFromAPI);
            setSender(senderFromAPI);
            SetAudience(audienceFromAPI);
            setReceiverType(receiver_type);
            setAttachedFilePath(attachedFilePathFromAPI);
            setSchoolImage(schoolImage);
        } catch (error) {
            console.error(error);
        }
    };

    const fetchTemplate = async () => {
        try {
            const response = await axios.post(`${process.env.REACT_APP_BROADCAST_SERVER}/email-template/template-list`, {
                id: temp_id,
            });

            if (response.data && response.data.templates && response.data.templates.length > 0) {
                const template = response.data.templates[0];
                SetPreviewBody(template.preview_body);
            } else {
                console.error('Template data not found');
            }
        } catch (error) {
            console.error(error);
        }
    };

    const SendNowData = async () => {
        const currentDate = new Date();

        const dateOptions: Intl.DateTimeFormatOptions = { weekday: 'long', year: 'numeric', month: 'long', day: 'numeric' };
        const formattedDate = currentDate.toLocaleDateString(undefined, dateOptions);

        const timeOptions: Intl.DateTimeFormatOptions = { hour: 'numeric', minute: 'numeric', second: 'numeric', hour12: true };
        const formattedTime = currentDate.toLocaleTimeString(undefined, timeOptions);

        const datetime = `${formattedDate} ${formattedTime}`;

        try {
            const response = await axios.post(`${process.env.REACT_APP_BROADCAST_SERVER}/email/send-now`, {
                teplateid: templateId,
                sentDate: datetime,
            });
            window.location.href = `/broadcast/sent-mail`;
            setSuccessMessage('Email sent successfully');
        } catch (error) {
            console.error(error);
        }
    };

    const SendLaterData = async (date: Date) => {
        const formattedDate = `${date.getFullYear()}-${(date.getMonth() + 1).toString().padStart(2, '0')}-${date
            .getDate()
            .toString()
            .padStart(2, '0')} ${date.getHours().toString().padStart(2, '0')}:${date.getMinutes().toString().padStart(2, '0')}`;

        try {
            const response = await axios.post(`${process.env.REACT_APP_BROADCAST_SERVER}/email/send-later`, {
                teplateid: templateId,
                schedule_for_later: formattedDate,
            });
            window.location.href = `/broadcast/schedule-mail`;
        } catch (error) {
            console.error(error);
        }
    };

    const selectedStudentsString = sessionStorage.getItem('student_data_for_email');

    const [isScheduled, setIsScheduled] = useState(false);

    const handleSchedulingChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        setIsScheduled(event.target.value === 'Schedule for Later');
    };

    const [isSendNowSelected, setIsSendNowSelected] = useState(true);
    const shouldRenderAttachedDiv = attached_file_path !== '';
    const [isSendClicked, setIsSendClicked] = useState(false);
    const [isScheduleClicked, setIsScheduleClicked] = useState(false);
    const [loading, setLoading] = useState<boolean>(false);
    const [successMessage, setSuccessMessage] = useState('');
    const [errorMessage, setErrorMessage] = useState('');

    const addLog = async () => {
        const selectedStudentsString = sessionStorage.getItem('student_data_for_email');
        if (selectedStudentsString) {
            const selectedStudents: string[] = JSON.parse(selectedStudentsString);
            const schoolId = sessionStorage.getItem('sesinstituteId');

            try {
                if (receiver_type === 3) {
                    const response = await axios.post(`${process.env.REACT_APP_BROADCAST_SERVER}/email/add-lead-broadcast-log`, {
                        student_id: selectedStudents,
                        template_id: templateId,
                        school_id: sesinstituteId,
                        temp_id: temp_id,
                    });
                } else {
                    const response = await axios.post(`${process.env.REACT_APP_BROADCAST_SERVER}/email/add-email-log`, {
                        student_id: selectedStudents,
                        template_id: templateId,
                        school_id: sesinstituteId,
                        temp_id: temp_id,
                    });
                }
            } catch (error) {
                console.error('Error sending emails:', error);
            }
        }
    };

    const handleSendClick = () => {
        setIsSendClicked(true);
        setIsScheduleClicked(false);
        addLog();
        SendNowData();
    };

    const handleScheduleClick = () => {
        if (!startDate) {
            setErrorMessage('Please select a date and time.');
            return;
        }
        setIsSendClicked(false);
        setIsScheduleClicked(true);
        setErrorMessage('');
        addLog();
        SendLaterData(startDate);
    };

    const replacePlaceholder = (template: string, placeholders: { [key: string]: string }) => {
        let result = template;
        for (const [placeholder, replacement] of Object.entries(placeholders)) {
            result = result.replace(new RegExp(`{${placeholder}}`, 'g'), replacement);
        }
        return result;
    };

    const createMarkup = () => {
        const combinedHTML = replacePlaceholder(previewBody, { message, schoolImage });
        return { __html: combinedHTML };
    };

    return (
        <div className="main flex-1">
            <style>
                {`
          .custom-datepicker input {
            height: 50px;
          }
        `}
            </style>
            <div className="max-1140 mx-auto d-flex h-100">
                <div className="flex-1 d-flex flex-column mb-5">
                    <div className="pageContent flex-1 ps-0">
                        <div className="row">
                            <div className="col-md-12 mb-4">
                                <div className="white-block white-block-notopborderradius h-100 p-0">
                                    <div className="borderBottom border-2 custom-border-gray p-4 pb-3">
                                        <div className="d-flex flex-wrap justify-content-between align-items-center mx-0 mx-sm-1">
                                            <h3
                                                data-aos="fade-left"
                                                data-aos-delay="600"
                                                className="font-35 font-600 mb-0 block-title aos-init aos-animate">
                                                Preview
                                            </h3>
                                        </div>
                                    </div>
                                    <div className="row sd_formsec">
                                        <div
                                            className="d-flex flex-column justify-content-between align-items-center mx-auto max-630 pt-4 pb-4 px-0 aos-init aos-animate"
                                            data-aos="fade-up"
                                            data-aos-delay="900"
                                            id="no-notification">
                                            <form className="lstCustForm w-100 formrowpx mt-2 px-4 px-md-0">
                                                <div className="row g-4">
                                                    <div className="col-12">
                                                        <div className="row">
                                                            <div className="col-sm-4">
                                                                <span className="font-16 font-500">Title of the Broadcast</span>
                                                            </div>
                                                            <div className="col-sm-8" id="div_subject">
                                                                {subject}
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div className="col-12">
                                                        <div className="row">
                                                            <div className="col-sm-4">
                                                                <span className="font-16 font-500">Sender Name</span>
                                                            </div>
                                                            <div className="col-sm-8">{sender}</div>
                                                        </div>
                                                    </div>
                                                    <div className="col-12">
                                                        <div className="row">
                                                            <div className="col-sm-4">
                                                                <span className="font-16 font-500">Audience</span>
                                                            </div>
                                                            <div className="col-sm-8" id="noofstudent">
                                                                {numberOfStudents} Profiles
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div className="col-12">
                                                        <hr />
                                                    </div>
                                                    <div className="col-12 text-center">
                                                        <span className="font-18 font-500">Email Preview </span>
                                                    </div>
                                                    <div className="col-12">
                                                        <div className="border border-1 border-radius-6 preview-section">
                                                            {/*<div className="preview-header">
                                                                <div className="logo_preview d-none">
                                                                    <img
                                                                        src="images/preview_logo.jpg"
                                                                        width="150"
                                                                        className="img-fluid"
                                                                        alt=""
                                                                    />
                                                                </div>
                                                                <div className="univarsity_logo">
                                                                    <img
                                                                        src="images/dark-logo.svg1"
                                                                        width="150"
                                                                        className="img-fluid"
                                                                        alt=""
                                                                    />
                                                                </div>
                                                            </div>*/}
                                                            <div
                                                                className="preview-body"
                                                                id="preview-body"
                                                                dangerouslySetInnerHTML={createMarkup()}
                                                            />
                                                        </div>
                                                    </div>
                                                    {attached_file_path && (
                                                        <div className="col-12" id="attached">
                                                            <div className="row g-3 align-items-center">
                                                                <div className="col-md-auto">
                                                                    <label
                                                                        htmlFor="AudienceCategory"
                                                                        className="form-label m-0 mb-1 font-16 font-500 w-100">
                                                                        Attachments
                                                                    </label>
                                                                </div>
                                                                <div className="col-md">
                                                                    <a
                                                                        href={attached_file_path}
                                                                        className="btn btn-border-gray position-relative"
                                                                        target="_blank"
                                                                        rel="noopener noreferrer">
                                                                        <i className="icon-pdf me-1"></i>
                                                                        <span>Preview File</span>
                                                                    </a>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    )}

                                                    <div className="col-md-12">
                                                        <label className="form-label m-0 mb-2 font-16 font-500 w-100" htmlFor="Scheduling">
                                                            Scheduling:
                                                        </label>
                                                        <div className="row g-3 g-sm-5">
                                                            <div className="col-auto">
                                                                <div className="d-flex flex-wrap justify-content-center justify-content-sm-start align-items-center">
                                                                    <input
                                                                        type="radio"
                                                                        name="Scheduling"
                                                                        id="SendNow"
                                                                        onChange={handleSchedulingChange}
                                                                        checked={!isScheduled}
                                                                        className="form-check-input custom-border-84-20 me-2"
                                                                        value="Send Now"
                                                                    />
                                                                    <label
                                                                        htmlFor="SendNow"
                                                                        className="form-check-label font-16 font-500 ms-2">
                                                                        Send Now
                                                                    </label>
                                                                </div>
                                                            </div>
                                                            <div className="col-auto">
                                                                <div className="d-flex flex-wrap justify-content-center justify-content-sm-start align-items-center">
                                                                    <input
                                                                        type="radio"
                                                                        name="Scheduling"
                                                                        id="ScheduleForLater"
                                                                        className="form-check-input custom-border-84-20 me-2"
                                                                        value="Schedule for Later"
                                                                        checked={isScheduled}
                                                                        onChange={handleSchedulingChange}
                                                                    />
                                                                    <label
                                                                        htmlFor="ScheduleForLater"
                                                                        className="form-check-label font-16 font-500 ms-2">
                                                                        Schedule for Later
                                                                    </label>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                    {isScheduled && (
                                                        <div className="col-12 d-flex flex-column" id="later">
                                                            <label
                                                                className="form-label m-0 mb-2 font-16 font-500 w-100"
                                                                htmlFor="Scheduling">
                                                                Schedule for Later:
                                                            </label>
                                                            <DatePicker
                                                                className="custom-datepicker max-250 w-100 height-40 px-2"
                                                                selected={startDate}
                                                                onChange={handleDateChange}
                                                                showTimeSelect
                                                                dateFormat="MMMM d, yyyy h:mm aa"
                                                                minDate={new Date()}
                                                            />
                                                        </div>
                                                    )}
                                                </div>
                                            </form>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    {successMessage && <div className="success-message">{successMessage}</div>}
                    {errorMessage && <div className="error-message">{errorMessage}</div>}
                    {!isScheduled && !isSendClicked && (
                        <div className="row justify-content-end align-items-center mt-4 broadcast_submit">
                            <div className="my-0 d-flex flex-wrap justify-content-center">
                                <button id="done" onClick={handleSendClick} type="button" className="btn btn-publish">
                                    <span>Send</span>
                                </button>
                            </div>
                        </div>
                    )}
                    {!isSendClicked && isScheduled && (
                        <div className="row justify-content-end align-items-center mt-4 broadcast_submit">
                            <div className="my-0 d-flex flex-wrap justify-content-center">
                                <button
                                    id="schadule1"
                                    type="button"
                                    onClick={handleScheduleClick}
                                    className="btn btn-publish"
                                    disabled={loading}>
                                    <span>Schedule</span>
                                </button>
                            </div>
                        </div>
                    )}
                </div>
            </div>
        </div>
    );
};

export default TemplatePreview;
