import axios from 'axios';
import { useContext, useEffect, useState } from 'react';
import { useLoaderData, useLocation, useNavigate, useParams } from 'react-router-dom';
import { useImmerReducer } from 'use-immer';
import PageBuilder from '../../../library/Builders/PageBuilder/PageBuilder';
import { GlobalContext } from '../../../library/Core/GlobalContext';
import { SmartContext } from '../../../library/Core/SmartContext';
import smartReducer from '../../../library/Core/SmartReducer';
import { DispatchEvent, State } from '../../../library/Core/SmartTypes';
// import { onLogIn, onMobileNumberChange, onSignUp } from './LoginActionsOld';
import SessionContext from '../../../library/Core/SessionContext';
import { getInstituteProgramDomain } from '../../../library/Service/domainService';
import { onLogIn, onMobileNumberChange, onSignUp } from './LoginActions';
import LoginLayout from './LoginLayout';
import ReactGA from 'react-ga4';

const Login = () => {
    const { globalState } = useContext(GlobalContext);
    const { sessionState, sessionDispatch } = useContext(SessionContext);
    const { schoolDomain, invitedBy } = useParams();
    const [loginClicked, setLoginClicked] = useState(false);
    // to get the page name
    const location = useLocation();
    const pageName = location.pathname.includes('signup') ? 'alumni-signup' : 'alumni-login';
    const navigate = useNavigate();
    const [mode, setMode] = useState(pageName === 'alumni-login' ? 'login' : 'signUp');
    const MEASUREMENT_ID = process.env.REACT_APP_GA_KEY as string;

    const [state, dispatch] = useImmerReducer<State, DispatchEvent>(smartReducer, {
        flags: { isDataLoading: true, showFormErrors: 0 },
        formValidationErrors: {},
        actions: {
            SIGN_UP: onSignUp,
            LOG_IN: onLogIn,
            ROUTER_NAVIGATE: navigate,
            mobileNumber: onMobileNumberChange,
            loginClick: setLoginClicked,
        },
    } as any);

    let { formConfig } = useLoaderData() as State;

    pageName === 'alumni-login' ? mode === 'signUp' && setMode('login') : mode === 'login' && setMode('signUp');

    const handleNewUser = () => {
        setMode('signUp');
        dispatch({ type: 'RESET_VALIDATION_ERRORS' });
        // navigate(`/alumni/${schoolDomain}/signup`, { replace: false });
        navigate(`/user/${schoolDomain}/signup`, { replace: false });
    };

    const handleExistingUser = () => {
        setMode('login');
        dispatch({ type: 'RESET_VALIDATION_ERRORS' });
        // navigate(`/alumni/${schoolDomain}/login`, { replace: false });
        navigate(`/user/${schoolDomain}/login`, { replace: false });
    };

    useEffect(() => {
         // Initialize GA tracking when component mounts
         ReactGA.initialize(MEASUREMENT_ID);  
         ReactGA.send({ hitType: 'pageview', page: window.location.href });
         console.log("MEASUREMENT_ID",MEASUREMENT_ID);

        const loadSchoolSettings = async () => {
            let schoolId = '';
            const response = await axios.get(`${process.env.REACT_APP_STUDENT_SERVER}/v1/auth/school-info/${schoolDomain}`);
            localStorage.setItem('school_settings', JSON.stringify(response.data?.institute));
            localStorage.setItem('school_programs', JSON.stringify(response.data?.programs));
            localStorage.setItem('school-image', response.data.institute.logo);
            sessionStorage.setItem('school-image', response.data.institute.logo);
            // console.log(localStorage.getItem('user-logged-out')," localStorage.getItem('user-logged-out') ");
            // console.log(typeof localStorage.getItem('user-logged-out') , " TYPE OF ")
            if (localStorage.getItem('user-logged-out') != 'TRUE') {
                sessionDispatch({ type: 'SET', payload: { schoolSetting: response.data?.institute, programs: response.data?.programs } });
            }
            schoolId = response.data.institute.id;

            if (schoolId == null) alert('School not found. Please contact your school admin.');

            const domain = new Map([...globalState.domain]);
            domain.set('INSTITUTE_PROGRAM_CODE', getInstituteProgramDomain(response.data?.programs));

            dispatch({
                type: 'DATA_INIT',
                payload: {
                    formConfig,
                    data: { user: { schoolId: schoolId as any, invitedBy, provideUpdatesOnWhatsapp: true, agreeToUnivariety: false } },
                    domain,
                    routeInfo: { pageName, schoolDomain },
                },
            });
        };

        loadSchoolSettings();
    }, [mode]);

    useEffect(() => {
        localStorage.setItem('user-logged-out','TRUE');
        // Reset Local Storage
        const localStorageSchoolSettings = localStorage.getItem('school_settings');
        const localStorageschoolImage = localStorage.getItem('school-image');
        localStorage.clear();
        localStorage.setItem('school_settings', localStorageSchoolSettings ?? '');
        localStorage.setItem('school-image', localStorageschoolImage ?? '');

        // Reset Session Storage
        const sessionStorageBenefitsConfig = sessionStorage.getItem('benefits-config');
        const sessionStorageCarouselData = sessionStorage.getItem('carousel-data');
        const sessionStorageSchoolImage = sessionStorage.getItem('school-image');
        const sessionStorageActivityConfig = sessionStorage.getItem('activity-config');
        sessionStorage.clear();
        sessionStorage.setItem('benefits-config',sessionStorageBenefitsConfig ?? '');
        sessionStorage.setItem('carousel-data',sessionStorageCarouselData ?? '');
        sessionStorage.setItem('school-image',sessionStorageSchoolImage ?? '');
        sessionStorage.setItem('activity-config',sessionStorageActivityConfig ?? '');
    },[]);

    useEffect(() => {
        if (localStorage.getItem('user-logged-out') != 'TRUE') {
            if (sessionState?.userId && sessionState?.role === 'INSTITUTE_ADMIN') navigate('/management/dashboard');
            else if (sessionState?.studentId && loginClicked == true) navigate('/select-institute');
        }

        // if (sessionState?.userId && sessionState?.role === 'INSTITUTE_ADMIN') navigate('/management');
        // else if (sessionState?.studentId) navigate('/select-institute');
    }, [sessionState, navigate]);

    console.log(state, ' state');

    return (
        <SmartContext.Provider value={{ state, dispatch }}>
            <LoginLayout
                mode={pageName === 'alumni-login' ? 'login' : 'signUp'}
                handleNewUser={handleNewUser}
                handleExistingUser={handleExistingUser}>
                {!state.flags.isDataLoading && <PageBuilder />}
            </LoginLayout>
        </SmartContext.Provider>
    );
};

export default Login;
