import { useContext, useEffect, useState } from 'react';
import { useLoaderData, useNavigate } from 'react-router-dom';
import { useImmerReducer } from 'use-immer';
import PageBuilder from '../../../library/Builders/PageBuilder/PageBuilder';
import { GlobalContext } from '../../../library/Core/GlobalContext';
import { SmartContext } from '../../../library/Core/SmartContext';
import { isEmpty, isFormValid, setError } from '../../../library/Core/SmartFunctions';
import smartReducer from '../../../library/Core/SmartReducer';
import { DispatchEvent, State } from '../../../library/Core/SmartTypes';
import { axiosClient } from '../../../library/Service/axiosClient';
import { handleSave } from './Actions';
import Layout from './Layout';
import { getinstituteList } from '../../../library/Service/domainService';
import useManagementDashboard from '../../management/dashboard/useManagementDashboard';
import SessionContext from '../../../library/Core/SessionContext';
import axios from 'axios';
import { v4 as uuidv4 } from 'uuid';
import Swal from 'sweetalert2';

const PsychometricTestMapping = () => {
    const { globalState } = useContext(GlobalContext);
    const { getCampuses } = useManagementDashboard();
    const navigate = useNavigate();
    const { internal } = useLoaderData() as State;
    const { sessionState } = useContext(SessionContext);
    const allowedProgramLevels = ['8', '9', '10', '11', '12'];

    // const campuses = getCampuses();
    const campuses = (sessionState?.userTypeCode == 'INSTITUTE_ADMIN') ? getCampuses() : globalState.domain.get('INSTITUTE_DOMAIN')?.map((data) => {
        return { label: data.value, value: data.code };
    });

    const [instituteList, setinstituteList] = useState(getinstituteList('INSTITUTE_GROUP_CODE', campuses));

    const [state, dispatch] = useImmerReducer<State, DispatchEvent>(smartReducer, {
        flags: { isDataLoading: true, showFormErrors: 0 },
        formValidationErrors: {},
        actions: {
            SAVE: handleSave
        },
    } as any);

    const { formConfig, data, routeInfo } = useLoaderData() as State;

    useEffect(() => {
        const domain = new Map([...globalState.domain]);

        dispatch({
            type: 'DATA_INIT',
            payload: {
                formConfig,
                data: {
                    mappingData: state?.data?.mappingData || {
                        ...state?.data?.mappingData,
                        instituteId: 0
                    },
                    classes: state?.data?.mappingData?.classes || [{ id: 0, programLevel: 0, testIds: [] }]

                },
                domain,
                routeInfo,
            },
        });

        const setDomainData = async () => {
            try {
                if (instituteList.length > 1) {
                    dispatch({
                        type: 'SET_DOMAIN',
                        payload: { key: 'INSTITUTE_GROUP_CODE', value: instituteList },
                    });
                }
                else if (instituteList.length == 1) {
                    dispatch({ type: 'CONTROL_VALUE_CHANGE', payload: { dataKey: 'mappingData.instituteId', value: instituteList[0].code } });
                    await setProgramDomain();
                }
            } catch (error) {
                console.error('Error fetching program data:', error);
            }
        };

        setDomainData();
    }, []);

    useEffect(() => {
        if (isEmpty(state.data?.mappingData?.instituteId) || state.data?.mappingData?.instituteId === 0) {
            return;
        }
        let proceedFurther = true;

        const fetchData = async () => {
            try {
                const response = await axiosClient().get(`${process.env.REACT_APP_COMMON_API}/test/get-school-test-mapping/${state.data?.mappingData?.instituteId}`);
                console.log(response.data, " MAPPING DATA ");
                const mappingData = response?.data?.data;

                if (mappingData.length <= 0) {
                    Swal.fire({
                        title: "No programs added..!",
                        text: "Please add programs for this institute or try again with another institute..!!",
                        icon: "warning"
                    }).then(() => {
                        dispatch({
                            type: 'REFRESH_DATA',
                            payload: {
                                data: {
                                    ...state.data,
                                    mappingData: {
                                        ...state.data.mappingData,
                                        instituteId: 0
                                    }
                                }
                            }
                        });
                    });
                    return false;
                }
                let classes: any = [];
                mappingData.map((data: any, idx: number) => {
                    let testIds = data?.test_ids?.split(',');
                    let testIdData = testIds?.map((testId: number) => {
                        return {
                            testIds: testId
                        }
                    })

                    classes.push({
                        id: state.data.classes[idx]?.id ?? uuidv4(),
                        programLevel: data?.program_level,
                        testIds: testIdData
                    })
                })

                dispatch({
                    type: 'REFRESH_DATA',
                    payload: {
                        data: { ...state.data, classes: classes }
                    },
                });

            } catch (error) {
                console.error("Error fetching data:", error);
            }
        };
        setProgramDomain();
        fetchData();
    }, [state.data?.mappingData?.instituteId]);

    async function setProgramDomain() {
        try {
            const response = await axiosClient().post(`${process.env.REACT_APP_INTERNAL_ADMIN_SERVER}/admin/get-programs-by-institute-id`, {
                institute_id: state.data?.mappingData?.instituteId,
            });
            const programs = response.data.data.programs;

            programs.sort((a: any, b: any) => parseInt(a.program_level) - parseInt(b.program_level));

            let program_values = [];

            for (let i = 0; i < programs.length; i++) {
                if (!allowedProgramLevels.includes(programs[i]['program_level'])) {
                    continue;
                }
                program_values[i] = { 'label': programs[i]['name'], 'value': programs[i]['program_level'] };
            }

            dispatch({
                type: 'SET_DOMAIN',
                payload: { key: 'INSTITUTE_ATTENDED_PROGRAM_CODE', value: getinstituteList('INSTITUTE_ATTENDED_PROGRAM_CODE', program_values) },
            });
        } catch (error) {
            console.error('Error fetching program data:', error);
        }
    }

    // useEffect(() => {
    //     if (state.data?.mappingData?.instituteId != undefined) {
    //         fetchData();
    //     }
    // }, [state.data?.mappingData?.instituteId]);

    return (
        <SmartContext.Provider value={{ state, dispatch }}>
            <Layout>{!state.flags.isDataLoading && <PageBuilder />}</Layout>
        </SmartContext.Provider>
    );
};

const sendMoEngageEvent = async (user: any) => {
    try {
        const updatedUser = {
            ...user,
            reqType: 'Admin', // New key-value pair
        };
        const response = await axios.post(`${process.env.REACT_APP_MOENGAGE_API}/moengage/moengage-signup`, {
            userInfo: updatedUser
        });
    } catch (error) {
        console.error('Error fetching data:', error);
    }
};

export default PsychometricTestMapping;
