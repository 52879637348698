import { getUniversityImage } from '../../../common/styles/Images';
import { getDomainValueForCode } from '../../../library/Core/SmartFunctions';
import { getFieldOfStudyName } from '../alumni-profile/service/student-profile-view.service';

const CardUniversity = ({ alumni, cardType, state }: { alumni: any; cardType: string; state: any }) => {
    const getTitle = (alumni: any) => {
        let title = '';
        // let studyType = alumni?.pgUniversityId ? 'PG' : 'UG';
        // console.log(cardType, " cardType 222");
        let presentEducationStatusCode = alumni?.presentEducationStatusCode;

        switch (cardType) {
            case 'UG':
                if (presentEducationStatusCode === 'COMPLETED_STUDIES') {
                    title = 'Completed UG';
                } else {
                    title = 'Pursuing UG';
                }
                break;
            case 'PG':
                if (presentEducationStatusCode === 'COMPLETED_STUDIES') {
                    title = 'Completed PG';
                } else {
                    title = 'Pursuing PG';
                }
                break;
            case 'JOINED_UG':
                // title = 'Joined UG';
                if (presentEducationStatusCode === 'COMPLETED_STUDIES') {
                    title = 'Completed UG';
                } else {
                    title = 'Joined UG';
                }
                break;
            case 'JOINED_PG':
                // title = 'Joined PG';
                if (presentEducationStatusCode === 'COMPLETED_STUDIES') {
                    title = 'Completed PG';
                } else {
                    title = 'Joined PG';
                }
                break;
            case 'Doctoral':
                // title = 'Joined Doctoral';
                if (presentEducationStatusCode === 'COMPLETED_STUDIES') {
                    title = 'Completed Doctoral';
                } else {
                    title = 'Joined Doctoral';
                }
                break;
        }
        // return 'Pursuing UG';
        return title;
    };

    const getUniversityName = () => {
        return 'Uni Name';
    };

    const getFieldOfStudy = () => {
        return 'Medicine';
    };

    
    const getWorkDetails = (alumni:any) => {
        return (
            `<div class="alumni_univarsity_brife">
                <div class="alumni_education text-start">Working as</div>
                <div class="alumni_univarsity_name text-start">
                    <span class="text-black text-start">  
                    ${
                        alumni?.workingStatusCode === 'TEACHING_AND_RESEARCH'
                            ? (alumni?.outcomeJobInstituteId
                                ? getDomainValueForCode(alumni?.outcomeJobInstituteId, 'INSTITUTE_DOMAIN', state)
                                : alumni?.otherInstitute)
                            : (alumni?.jobCompanyId
                                ? (getDomainValueForCode(alumni?.jobCompanyId, 'COMPANY_CODE', state) === alumni?.jobCompanyId
                                    ? alumni?.companyName
                                    : getDomainValueForCode(alumni?.jobCompanyId, 'COMPANY_CODE', state))
                                : alumni?.companyName)
                        }
                    </span>
                </div>
                ${alumni.designation ? (
                    `<div class="alumni_univarsity_subject">
                        <i className='icon-brifecase'></i><span class="text-start">${alumni.designation}</span>
                    </div>`
                ) : ''}
            </div>`
        );
    };


    return (
        <div key={alumni.uuid} className="alumni_univarsity_details pursuing">
            {(cardType == 'UG' || cardType == 'JOINED_UG') && (
                <div className="alumni_univarsity_details_inner_cover">
                    <div className="alumni_univarsity_logo">
                        <img src={getUniversityImage(alumni?.collegeIconFilePath)} alt="" />
                    </div>
                    <div className="alumni_univarsity_brife">
                        <div className="alumni_education">
                            <div className="row g-2">
                                <div className="col">{getTitle(alumni)}</div>
                                {(alumni?.presentEducationStatusCode === 'PURSUING_STUDIES' && alumni?.isWorking === 1 &&
                                // (alumni?.jobCompanyId ? getDomainValueForCode(alumni?.jobCompanyId, 'COMPANY_CODE', state) : alumni?.companyName) != null) && (
                                    (alumni?.jobCompanyId ? getDomainValueForCode(alumni?.jobCompanyId, 'COMPANY_CODE', state) : alumni?.companyName) != null
                                    || 
                                    (alumni?.outcomeJobInstituteId ? getDomainValueForCode(alumni?.outcomeJobInstituteId, 'INSTITUTE_DOMAIN', state) : alumni?.outcomeJobInstituteName) != null
                                    ) && (
                                <>
                                <div className="col-auto">
                                        <a
                                            href="javascript:;"
                                            className="btn-white-tooltip"
                                            data-bs-toggle="tooltip"
                                            data-bs-html="true"
                                            title=""
                                            data-bs-original-title={getWorkDetails(alumni)}>
                                            Work Status
                                        </a>
                                </div>
                                </>
                             )}
                            </div>
                        </div>
                        <div className="alumni_univarsity_name">
                            <span title={alumni.universityName}>{alumni.universityName}</span>
                            {alumni?.isPrestigiousCollege == 1 && (
                                <i className="ms-1">
                                    <img src="https://miles.univariety.com/school/public/images/subtraction.svg" alt="" />
                                </i>
                            )}
                        </div>
                        <div className="alumni_univarsity_subject">
                            {alumni.fieldOfStudyCode && (
                                // apply this bcz sometimes it's return code not value 
                                getFieldOfStudyName(alumni.fieldOfStudyCode, state) !== alumni.fieldOfStudyCode ? (
                                    <span>{getFieldOfStudyName(alumni.fieldOfStudyCode, state)}</span>
                                ) : null
                            )}
                        </div>
                    </div>
                </div>
            )}

            {(cardType == 'PG' || cardType == 'JOINED_PG' || cardType=='Doctoral') && (
                <div className="alumni_univarsity_details_inner_cover">
                    <div className="alumni_univarsity_logo">
                        <img src={getUniversityImage(alumni?.pgUniversityIcon)} alt="" />
                    </div>
                    <div className="alumni_univarsity_brife">
                        <div className="alumni_education">
                            <div className="row g-2">
                                <div className="col">{getTitle(alumni)}</div>
                                {(alumni?.presentEducationStatusCode === 'PURSUING_STUDIES' && alumni?.isWorking === 1 &&
                                (alumni?.jobCompanyId ? getDomainValueForCode(alumni?.jobCompanyId, 'COMPANY_CODE', state) : alumni?.companyName) != null) && (
                                <>
                                <div className="col-auto">
                                        <a
                                            href="javascript:;"
                                            className="btn-white-tooltip "
                                            data-bs-toggle="tooltip"
                                            data-bs-html="true"
                                            title=""
                                            data-bs-original-title={getWorkDetails(alumni)}>
                                            Work Status
                                        </a>
                                </div>
                                </>
                             )}
                            </div>
                        </div>
                        <div className="alumni_univarsity_name">
                            <span>{alumni.pgUniversityName}</span>
                            {alumni?.pgIsPrestigiousCollege == 1 && (
                                <i className="ms-1">
                                    <img src="https://miles.univariety.com/school/public/images/subtraction.svg" alt="" />
                                </i>
                            )}
                        </div>
                        {alumni.pgFieldOfStudyCode && (
                        <div className="alumni_univarsity_subject">
                            <i className=""></i>
                            <span>{getFieldOfStudyName(alumni.pgFieldOfStudyCode, state)}</span>
                        </div>
                        )}
                    </div>
                </div>
            )}
        </div>
    );
};

export default CardUniversity;
