import { activateUserSession, isEmpty, isFormValid, setError } from '../../../../library/Core/SmartFunctions';
import { ControlOnChangeArguments, DispatchEvent, State } from '../../../../library/Core/SmartTypes';
import { axiosClient } from '../../../../library/Service/axiosClient';
import ScreenNavigationService, { AlumniProfileEditScreens } from '../../../../services/screen-navigation.service';
// import { toStudentEntity } from '../../../../services/student-mapper.service';
import StudentService from '../../../../services/student.service';
import { navigateToNextStep } from '../../services/profile-next-step-navigator';

export const handleNext = (
    event: React.SyntheticEvent<HTMLButtonElement>,
    state: State,
    dispatch: (dispatchEvent: DispatchEvent) => void,
    globalState: any,
    globalDispatch: (dispatchEvent: DispatchEvent) => void,
    sessionState: any,
    sessionDispatch: (dispatchEvent: DispatchEvent) => void
) => {
    event.preventDefault();
    
    const inValidDate = isValidDate(state, dispatch);
    const isImpersonatedSession = sessionStorage.getItem('is-impersonated-session') === 'YES';
    const customValidationPassed = isFormValidBasedOnCustomValidation(state, dispatch);
    // let customValidationPassed = true;
    if (!isImpersonatedSession && ((!isFormValid(state, dispatch,  ['jobsApplied.*.companyId','entreprenurship.companyId']) || !customValidationPassed) || !inValidDate)) {
        dispatch({ type: 'SHOW_ERRORS' });
        return;
    }
    
    let entreprenurship = state?.data['entreprenurship'];
    let jobsApplied = state?.data['profile'].appliedForThePlacement == true ? state?.data['jobsApplied'].map((job:any) => {
        return {...job,isOutcome : true};
    }) : [];
    

    let jobs = [{...entreprenurship, recordTypeCode: 'JOINED', jobCategory: 'ENTREPRENEURSHIP',designation:'Founder',isOutcome : true},
                ...jobsApplied];

    StudentService.pageSave(
        state.routeInfo.id,
        state.routeInfo.pageName,
        StudentService.toStudentEntity({
            id: state.routeInfo.id,
            jobs: jobs,
            profile: state?.data['profile'],
        })
    ).then((response) => {
        if (response?.status === 200) {
            navigateToNextStep(AlumniProfileEditScreens.WORK_DETAILS, state, dispatch, sessionState, sessionDispatch);

        }
    });
};

const isFormValidBasedOnCustomValidation = (state: State, dispatch: (dispatchEvent: DispatchEvent) => void) => {
    const errorMessages = [] as any[];
    let isFormInvalid = false;
    const jobs = state.data.jobsApplied;
    const entreprenurship = state.data.entreprenurship;

    if (isEmpty(entreprenurship?.companyId) && isEmpty(entreprenurship?.otherCompany)) {
        isFormInvalid = true;
        setError(`entreprenurship.companyId`, [`Please enter "Name of the Company"`], dispatch);
    } else {
        setError(`entreprenurship.companyId`, [], dispatch);
    }

    jobs.forEach((job: any, index: number) => {
        if (state?.data?.profile?.appliedForThePlacement) {
            if (isEmpty(job?.companyId) && isEmpty(job?.otherCompany)) {
                isFormInvalid = true;
                setError(`jobsApplied.${index}.companyId`, [`Please enter "Name of the Company"`], dispatch);
            } else {
                setError(`jobsApplied.${index}.companyId`, [], dispatch);
            }
        }else{
            setError(`jobsApplied.${index}.companyId`, [], dispatch); //clear error appliedForThePlacement set to false
        }
    });

    // Dispatch the validation errors to the state
    if ( isFormInvalid ) {
        dispatch({
            type: 'SET_FIELD_VALIDATION_ERRORS',
            payload: { dataKey: 'jobsApplied', errorMessages },
        });
        dispatch({
            type: 'SET_FIELD_VALIDATION_ERRORS',
            payload: { dataKey: 'entreprenurship', errorMessages },
        });
    }
    
    return !isFormInvalid;
};


// export const onCurrentJobSelectionChange = (props: ControlOnChangeArguments) => {
//     const rowIndex = parseInt(props.parentDataKey.split('.').pop() as string);
//     const jobs = props.state.data.jobs.map((row: any, index: number) => {
//         return index == rowIndex ? { ...row, endYear: '', isCurrent: false } : { ...row, isCurrent: false };
//     });

//     props.dispatch({ type: 'SET_FIELD_VALIDATION_ERRORS', payload: { dataKey: `${props.parentDataKey}.jobStartAndEndDates`, value: [] } });

//     props.dispatch({ type: 'CONTROL_VALUE_CHANGE', payload: { dataKey: 'jobs', value: jobs } });
// };

export const onCurrentJobSelectionChange = (props: ControlOnChangeArguments) => {
   if(!props.state.data.entreprenurship?.isCurrent){
    props.dispatch({ type: 'SET_FIELD_VALIDATION_ERRORS', payload: { dataKey: `${props.parentDataKey}.industryCode`, value: [] } });
   }
};

export const onStartDateChange = (props: ControlOnChangeArguments) => {
    alert(props.row.endDate);
    const errorMessages =
        new Date(props.value) > new Date(props.row.endDate) ? ['Start year should be less than or equal to End year'] : [];
    // props.dispatch({
    //     type: 'SET_FIELD_VALIDATION_ERRORS',
    //     payload: { dataKey: `${props.parentDataKey}.startDate`, errorMessages },
    // });

    props.errorMessages.push(errorMessages);
};

export const onEndDateChange = (props: ControlOnChangeArguments) => {
    const errorMessages =
        new Date(props.value) < new Date(props.row.startDate) ? ['End year should be greater than or equal to Start year'] : [];
    props.errorMessages.push(errorMessages);
};

export const handleCompleteLater = (state: State, dispatch: (dispatchEvent: DispatchEvent) => void) => {
    document.getElementsByClassName('modal').item(0)?.setAttribute('style', 'display:block');
    // state.actions['ROUTER_NAVIGATE']('/alumni/onboarding');
    //ScreenNavigationService.navigateToNextStep(AlumniProfileEditScreens.WORK_DETAILS, state, dispatch);
};

const isValidDate = (state: State, dispatch: (dispatchEvent: DispatchEvent) => void) => {
    const job = state?.data['entreprenurship'];
    let isFormValid = true;

        if (isEmpty(job?.startDate)) {
            setError(`entreprenurship.startDate`, [`Please enter "Start Date" `], dispatch);
            isFormValid = false;
        } else {
            setError(`entreprenurship.startDate`, [], dispatch);
        }

        // if ((job?.isCurrent === false || isEmpty(job?.isCurrent)) && isEmpty(job?.endDate)) {
        //     setError(`entreprenurship.endDate`, [`Please enter "End Date" `], dispatch);
        //     isFormValid = false;
        // } else {
        //     setError(`entreprenurship.endDate`, [], dispatch);
        // }

        // if (new Date(job?.startDate) >= new Date(job?.endDate)) {
        //     console.log('Invalid date');
        // }
        // if (job?.isCurrent === false || isEmpty(job?.isCurrent)) {
        //     if (new Date(job?.startDate) > new Date(job?.endDate)) {
        //         setError(`entreprenurship.endDate`, [`End year should be greater than or equal to Start year`], dispatch);
        //         isFormValid = false;
        //     } else {
        //         setError(`entreprenurship.endDate`, [], dispatch);
        //     }
        // }

    return isFormValid;
};
