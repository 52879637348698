import { useContext, useEffect, useState } from 'react';
import { SmartContext } from '../../../../../../library/Core/SmartContext';
import EditDemographicssModal from './EditDemographicssModal';
import EditDemographicsPageConfig from './FormConfiguration/EditDemographicsPageConfig.json';
import ProfileMarkerService from './ProfileMakerService';
import { Tooltip } from 'bootstrap';
import { isEmpty } from '../../../../../../library/Core/SmartFunctions';

type DemographicsProps = {
    age: { label: string,toolTip:string };
    genderCode: { label: string,toolTip:string };
    isSpouseSameInstitute: { label: string; isComment: string,toolTip:string };
    isSiblingSameInstitute: { label: string; isComment: string,toolTip:string };
    StudiedAbroad: { label: string,isComment: string,toolTip:string };
    CurrentlyAbroad: { label: string,isComment: string,toolTip:string };
    LivedAbroadandBack: { label: string; isComment: string,toolTip:string };
    DidnotTakeEducationloan: { label: string; isComment: string,toolTip:string };
    iswealthyNeighborhood:{ label: string; isComment: string,toolTip:string };
};

const DemographicsProps: DemographicsProps = {
    age: { label: 'Age', toolTip:"Age range of the alumnus"},
    genderCode: { label: 'Gender', toolTip:"Gender of the alumnus" },
    isSpouseSameInstitute: { label: 'Spouse also from same Institute', isComment: 'spouseComment', toolTip:"The spouse (husband/wife) of the alumnus also studied in the same institute making both of them alumni of your institute" },
    isSiblingSameInstitute: { label: 'Sibling also from same Institute', isComment: 'siblingComment', toolTip:"The sibling (brother/sister) of the alumnus also studied in the same institute making both of them alumni of your institute" },
    StudiedAbroad: { label: 'Study Abroad',isComment:'StudiedAbroadComment', toolTip:"Alumnus has studied in any institute outside the country" },
    CurrentlyAbroad: { label: 'Currently Abroad',isComment:'CurrentlyAbroadComment', toolTip:"Alumnus is currently living outside the country" },
    LivedAbroadandBack: { label: 'Lived Abroad and Back', isComment: 'LivedAbroadandBackComment', toolTip:"Alumnus is currently in the country, but at some point either studied or lived outside the country " },
    DidnotTakeEducationloan: { label: 'Did not Take Education loan', isComment: 'EducationLoanComment', toolTip:"Alumnus did not take any education loan to fund their studies when they were a student at your institute" },
    iswealthyNeighborhood: { label: 'Wealthy Neighborhood', isComment: 'wealthyNeighborhoodAddress', toolTip:"Alumnus is currently living in a wealthy neighborhood of their city" },

};

const keys = Object.keys(DemographicsProps) as (keyof DemographicsProps)[];

const 

Demographics = () => {
    const { state, dispatch } = useContext(SmartContext);
    const { fetchUserFromSameInstitute,handleOpenLogsModal } = ProfileMarkerService();
    const handleOpenModal = async (key: string) => {
        dispatch({
            type: 'REFRESH_DATA',
            payload: { data: { ...state.data, visibleControl: key } },
        });
        dispatch({ type: 'TOGGLE_MODAL_VISIBILITY', payload: { modalName: 'showDemoGraphicsModal' } });
    };
    //const userListFromSameInstitute = fetchUserFromSameInstitute(state.internal.model.id);
    const model = state.internal.model;
    const [isConfigAvailable, setisConfigAvailable] = useState(false);
    const markers = state.data.marker || [];

    useEffect(() => {
        const tooltipTriggerList = [].slice.call(document.querySelectorAll('[data-bs-toggle="tooltip"]'));
        const tooltipList = tooltipTriggerList.map(function (tooltipTriggerEl) {
            return new Tooltip(tooltipTriggerEl);
        });
    }, [state.data.demographics]);

    useEffect(() => {
        const demographicsData = { ...state.data.demographics };
        Object.keys(DemographicsProps).forEach((key) => {
            const demographicsKey = key as keyof DemographicsProps;
            const prop = DemographicsProps[demographicsKey];
            if (prop && typeof prop === 'object' && 'isComment' in prop) {
                const isCommentField = prop.isComment;
                if (demographicsData[demographicsKey] === false) {
                    if (isCommentField) {
                        demographicsData[isCommentField as keyof typeof demographicsData] = '';
                    }
    
                    if (demographicsKey === 'isSpouseSameInstitute') {
                        demographicsData['spauseId'] = '';
                    }

                    if (demographicsKey === 'isSiblingSameInstitute') {
                        demographicsData['siblingId'] = '';
                    }
                }
            }
        });
    
        if (demographicsData.iswealthyNeighborhood === false) {
            demographicsData.city = '';
            demographicsData.country = '';
            demographicsData.wealthyNeighborhoodAddress = '';
            demographicsData.locality = '';
            demographicsData.pinCode = '';
        }
        if (JSON.stringify(state.data.demographics) !== JSON.stringify(demographicsData)) {
            dispatch({
                type: 'CONTROL_VALUE_CHANGE',
                payload: { dataKey: 'demographics', value: demographicsData },
            });
        }
    }, [state.data.demographics, dispatch]);
    

    useEffect(() => {
        const fetchData = async () => {
            try {
                const userListFromSameInstitute = await fetchUserFromSameInstitute(state.internal.model.id,'Demographics');
                let userListFromSameInstituteForSibling = await fetchUserFromSameInstitute(state.internal.model.id,'Sibling');
                const domainMap = new Map([...state.domain]);
                console.log(userListFromSameInstitute);
                domainMap.set('USER_FROM_SAME_INSTITUTE', userListFromSameInstitute);
                console.log(userListFromSameInstituteForSibling,'userListFromSameInstituteForSibling')
                domainMap.set('USER_FROM_SAME_INSTITUTE_FOR_SIBLING', userListFromSameInstituteForSibling);

                const config = await Promise.resolve(EditDemographicsPageConfig);

                const defaultCountry = state?.data?.profile?.residingCountryCode; 
                const defaultCity = state?.data?.profile?.residingCityCode; 
                dispatch({
                    type: 'DATA_INIT',
                    payload: {
                        formConfig: config,
                        data: {
                            ...state.data,
                            demographics: {
                              ...state.data.marker?.demographics,
                              country: state.data.marker?.demographics?.country || defaultCountry,
                              city: state.data.marker?.demographics?.city || defaultCity,
                            },
                            visibleControl: {},
                        },
                        domain: domainMap,
                        internal: state.internal,
                        routeInfo: state.routeInfo,
                    },
                });
                setisConfigAvailable(true);
            } catch (error) {
                console.error('Error fetching data:', error);
            }
        };

        fetchData();
    }, []);

    const isMarkerEnabled = (key: string): boolean => {
        const value = markers?.demographics?.[key];
        if (value === true) return true;
        if (typeof value === 'string') {
          if (!isEmpty(value)) {
            return true;
          }
        }
        return false;
      };

    return (
        <div className="tab-pane fade active show" id="v-pills-demographics" role="tabpanel" aria-labelledby="v-pills-demographics-tab">
            <div className="max-470 mx-auto">
                <div className="row g-3">
                    {/* Replace this block with dynamic data */}
                    {keys.map((key) => {
                    const markerExists = key in markers?.demographics;

                    const markerEnabled = isMarkerEnabled(key);
                
                    const comment = (DemographicsProps[key] as { label: string; isComment?: string }).isComment
                        ? state.data.demographics?.[(DemographicsProps[key] as { label: string; isComment?: string }).isComment!] || ''
                        : '';
                
                    const value = markerExists ? markers.demographics[key] : null;
                
                    const spauseId = state.data.demographics?.spauseId;

                    const siblingId = state.data.demographics?.siblingId;

                        return (
                            <div className="col-12" key={key}>
                                <div className="row g-3 align-items-center">
                                    <div className="col">
                                    <div className={`listed-border ${markerEnabled ? '' : 'disabled'}`}>                                            <i className="tick"></i>
                                            <p onClick={() => handleOpenModal(key)}>
                                                {DemographicsProps[key].label}{' '}
                                                {((key === 'isSpouseSameInstitute' && spauseId) || (key === 'isSiblingSameInstitute' && siblingId)) ? (
                                                    <a 
                                                        className="text-white" 
                                                        href={`/alumni/${(key === 'isSpouseSameInstitute') ? spauseId : siblingId}/profile`} 
                                                        target="_blank"
                                                        onClick={(event) => event.stopPropagation()} // Prevent the click event from bubbling up
                                                    >
                                                        View Profile
                                                    </a>
                                                ) : (
                                                    value ? ` - ${value == 1 ? `Yes` : value}` : ''
                                                )}
                                            </p>                              
                                            {(comment !== undefined && comment !== null && comment.trim() !== '') && (
                                                <span className="note-wrap" data-bs-toggle="tooltip" data-bs-html="true" title={comment}>
                                                    <i className="icon-note"></i>
                                                </span>
                                            )}
                                        </div>
                                    </div>
                                    <div className="col-auto">
                                        <i
                                            className="icon-info"
                                            data-bs-toggle="tooltip"
                                            title=""
                                            data-bs-original-title={DemographicsProps[key].toolTip}
                                            aria-label={DemographicsProps[key].toolTip}></i>
                                    </div>
                                </div>
                            </div>
                        );
                    })}
                    <div className="col-12 pt-3">
                        <button className="btn btn-dark-blue w-auto height-40 px-4 d-flex align-items-center mx-auto" onClick={() => handleOpenLogsModal()}>
                        <span className="text-white">View Update Logs</span>
                        </button>
                    </div>
                    {/* Repeat similar blocks for other items */}
                </div>
            </div>
            {isConfigAvailable && <EditDemographicssModal userdata={{ model }} DemographicsProps={DemographicsProps} />}
        </div>
    );
};
export default Demographics;
